import {  useRef, useState } from "react";
import { BrowserQRCodeReader } from '@zxing/library';
import './App.css';
import logo from './logo.jpg';

function App() {

    const [company, setCompany] = useState("");
    const [currentCustomer, setCurrentCustomer] = useState(false);
    const [role, setRole] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [productLine, setProductLine] = useState([]);
    const [department, setDepartment] = useState([]);
    const [reason, setReason] = useState([]);
    const [note, setNote] = useState("");
    const formRef = useRef(null)
    
    function handleSubmit(event) {
        event.preventDefault()
        if (!formRef.current) return
        const row = {
          "toTop": true,
          "cells": [
              {
                "columnId": 3013839193853828,
                "value": company
              },
              {
                "columnId": 7517438821224324,
                "value": currentCustomer
              },
              {
                "columnId": 1887939287011204,
                "value": role
              },
              {
                "columnId": 6391538914381700,
                "value": name
              },
              {
                "columnId": 4139739100696452,
                "value": email
              },
              {
                "columnId": 8643338728066948,
                "value": telephone
              },
              {
                "columnId": 6936359273975684,
                "value": address
              },
              {
                "columnId": 1306859739762564,
                "value": city
              },
              {
                "columnId": 5810459367133060,
                "value": state
              },
              {
                "columnId": 3558659553447812,
                "value": zip
              },
              {
                "columnId": 1866862540312452,
                "value": productLine?.join(', ')
              },
              {
                "columnId": 8062259180818308,
                "value": department?.join(', ')
              },
              {
                "columnId": 743909786341252,
                "value": reason?.join(', ')
              },
              {
                "columnId": 5247509413711748,
                "value": note
              }
            ]
        }

        fetch("https://checkin-web-node.azurewebsites.net/addrow",
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(row)
        })
        .then(res => res.json())
        .then(()=> {
          formRef?.current?.submit()
        })
        .catch(error => console.log('Error:', error));
    }

    function setProductLineValue(value){
      if(productLine?.includes(value)){
        const filteredArray = productLine.filter(val => val !== value)
        setProductLine(filteredArray)
      }
      else{
        setProductLine(prevVal => [...prevVal, value])
      }
    }

    function setDeparmentValue(value){
      if(department?.includes(value)){
        const filteredArray = department.filter(val => val !== value)
        setDepartment(filteredArray)
      }
      else{
        setDepartment(prevVal => [...prevVal, value])
      }
    }

    function setReasonValue(value){
      if(reason?.includes(value)){
        const filteredArray = reason.filter(val => val !== value)
        setReason(filteredArray)
      }
      else{
        setReason(prevVal => [...prevVal, value])
      }
    }
   
    const [display, setDisplay] = useState("none");
  
    async function scanCode() {
      try {
        setDisplay("block");
        const codeReader = new BrowserQRCodeReader();
        const previewElem = document.querySelector(
          "#test-area-qr-code-webcam video"
        );

        const result = await codeReader.decodeOnceFromVideoDevice(undefined, previewElem);
        const values = result.getText().split('$')

        const ColumnNames = [
          "Id",
          "Industry",
          "Event",
          "First_name",
          "Last_name",
          "Role",
          "Company",
          "Address",
          "Unknown1",
          "City",
          "State",
          "Zip_code",
          "Country",
          "Telephone",
          "Unknown2",
          "Email"
        ];

        const ScanResults = {};

        ColumnNames.forEach((name, index) => {
          ScanResults[name] = values[index] || "";
        });

        setCompany(ScanResults.Company)
        setRole(ScanResults.Role)
        setName(`${ScanResults.First_name} ${ScanResults.Last_name}`)
        setEmail(ScanResults.Email)
        setTelephone(ScanResults.Telephone)
        setAddress(ScanResults.Address)
        setCity(ScanResults.City)
        setState(ScanResults.State)
        setZip(ScanResults.Zip_code)

        setDisplay("none");
        codeReader.reset()
      } catch (error) {
        console.log(error);
      }
    }

    function endScan(){
      setDisplay("none");
    }

  return (
    <>
    <img src={logo} alt="famouslogo" className='px-4 py-2 h-14'/>
    <form ref={formRef}  onSubmit={handleSubmit} className='px-4 py-4 flex justify-center bg-gray-100'>
          <div className='w-full lg:w-1/2'>
            {/* QR Code Scanner */}
            <div className='flex justify-center'>
              <div id="test-area-qr-code-webcam" style={{ display }}>
              <video className='h-80'></video>
              </div>
              {display === 'none' && <button onClick={scanCode} className="inline-block shrink-0 rounded-md border border-gray-600 bg-gray-700 px-8 py-3 text-sm font-medium text-white transition">Scan QR Code</button>}
              <button style={{ display }} onClick={endScan} className="h-7 w-7 ml-1 rounded-full border border-red-500 bg-red-500 text-sm font-medium text-white">X</button>
            </div>

            <div className='sm:flex sm:items-end pb-2'>
              <div className='gap-x-2 my-2 mx-2 grow'>
                <label htmlFor="company" className="block text-md font-medium text-gray-700 mb-1">
                  Company
                </label>
                <input
                  type="text"
                  id="company"
                  value={company}
                  autoComplete="off"
                  className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={(event) => setCompany(event.target.value)}
                />
              </div>
              <div className='flex items-center my-2 mx-2 pt-1'>
                <label htmlFor="currentCustomer" className="text-md font-medium text-gray-700 pr-2">
                  Current Customer
                </label>
                <input
                  type="checkbox"
                  id="currentCustomer"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setCurrentCustomer(!currentCustomer)}
                />
              </div>
            </div>
            <div className=' gap-x-2 my-2 mx-2'>
              <label htmlFor="role" className="block text-md font-medium text-gray-700 mb-1">
                Role
              </label>
              <input
                type="text"
                id="role"
                value={role}
                autoComplete="off"
                className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                onChange={(event) => setRole(event.target.value)}
              />
            </div>
            <div className=' gap-x-2 my-2 mx-2'>
              <label htmlFor="name" className="block text-md font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                autoComplete="off"
                className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className='sm:flex sm:items-center'>
              <div className=' gap-x-2 my-2 mx-2 grow'>
                <label htmlFor="email" className="block text-md font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  autoComplete="off"
                  className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              <div className=' gap-x-2 my-2 mx-2 grow'>
                <label htmlFor="telephone" className="block text-md font-medium text-gray-700 mb-1">
                  Telephone
                </label>
                <input
                  type="tel"
                  id="telephone"
                  value={telephone}
                  autoComplete="off"
                  className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={(event) => setTelephone(event.target.value)}
                />
              </div>
            </div>

            <div className='sm:flex sm:items-center'>
              <div className=' gap-x-2 my-2 mx-2 grow'>
                <label htmlFor="address" className="block text-md font-medium text-gray-700 mb-1">
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  value={address}
                  autoComplete="off"
                  className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={(event) => setAddress(event.target.value)}
                />
              </div>
              <div className=' gap-x-2 my-2 mx-2 grow'>
                <label htmlFor="city" className="block text-md font-medium text-gray-700 mb-1">
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  value={city}
                  autoComplete="off"
                  className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={(event) => setCity(event.target.value)}
                />
              </div>
            </div>

            <div className='flex items-center'>
              <div className='gap-x-2 my-2 mx-2 grow'>
                <label htmlFor="state" className="block text-md font-medium text-gray-700 mb-1">
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  value={state}
                  autoComplete="off"
                  className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={(event) => setState(event.target.value)}
                />
              </div>
              <div className='gap-x-2 my-2 mx-2 grow'>
                <label htmlFor="zip" className="block text-md font-medium text-gray-700 mb-1">
                  Zip Code
                </label>
                <input
                  type="text"
                  id="zip"
                  value={zip}
                  autoComplete="off"
                  className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={(event) => setZip(event.target.value)}
                />
              </div>
            </div>

            {/* Product Line Checkboxes */}
            <div className=' gap-x-2 my-3 mx-2 pt-2'>
              <div className="block text-lg font-medium text-gray-700 mb-1 underline">
                Product Line
              </div>
            </div>
            <div className='flex flex-wrap gap-x-3'>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="erp" className="block text-md font-medium text-gray-700 mb-1">
                  ERP
                </label>
                <input
                  type="checkbox"
                  id="erp"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setProductLineValue("ERP")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="wms" className="block text-md font-medium text-gray-700 mb-1">
                  WMS
                </label>
                <input
                  type="checkbox"
                  id="wms"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setProductLineValue("WMS")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="fis" className="block text-md font-medium text-gray-700 mb-1">
                  FIS
                </label>
                <input
                  type="checkbox"
                  id="fis"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setProductLineValue("FIS")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="bi" className="block text-md font-medium text-gray-700 mb-1">
                  BI
                </label>
                <input
                  type="checkbox"
                  id="bi"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setProductLineValue("BI")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="apps" className="block text-md font-medium text-gray-700 mb-1">
                  Apps
                </label>
                <input
                  type="checkbox"
                  id="apps"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setProductLineValue("Apps")}
                />
              </div>
            </div>

             {/* Department Checkboxes */}
             <div className=' gap-x-2 my-3 mx-2 pt-2'>
              <div className="block text-lg font-medium text-gray-700 mb-1 underline">
                Department
              </div>
            </div>
            <div className='flex flex-wrap gap-x-3'>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="sales" className="block text-md font-medium text-gray-700 mb-1">
                  Sales
                </label>
                <input
                  type="checkbox"
                  id="sales"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setDeparmentValue("Sales")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="support" className="block text-md font-medium text-gray-700 mb-1">
                  Support
                </label>
                <input
                  type="checkbox"
                  id="support"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setDeparmentValue("Support")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="custom" className="block text-md font-medium text-gray-700 mb-1">
                  Custom
                </label>
                <input
                  type="checkbox"
                  id="custom"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setDeparmentValue("Custom")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="ns" className="block text-md font-medium text-gray-700 mb-1">
                  NS
                </label>
                <input
                  type="checkbox"
                  id="ns"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setDeparmentValue("NS")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="billing" className="block text-md font-medium text-gray-700 mb-1">
                  Billing
                </label>
                <input
                  type="checkbox"
                  id="billing"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setDeparmentValue("Billing")}
                />
              </div>
            </div>

            {/* Reason Checkboxes */}
            <div className=' gap-x-2 my-3 mx-2 pt-2'>
              <div className="block text-lg font-medium text-gray-700 mb-1 underline">
                Reason
              </div>
            </div>
            <div className='flex flex-wrap gap-x-3'>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="compliment" className="block text-md font-medium text-gray-700 mb-1">
                  Compliment
                </label>
                <input
                  type="checkbox"
                  id="compliment"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setReasonValue("Compliment")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="issue" className="block text-md font-medium text-gray-700 mb-1">
                  Issue
                </label>
                <input
                  type="checkbox"
                  id="issue"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setReasonValue("Issue")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="request" className="block text-md font-medium text-gray-700 mb-1">
                  Request
                </label>
                <input
                  type="checkbox"
                  id="request"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setReasonValue("Request")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="upgrade" className="block text-md font-medium text-gray-700 mb-1">
                   Upgrade
                </label>
                <input
                  type="checkbox"
                  id="upgrade"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setReasonValue("Upgrade")}
                />
              </div>
              <div className='flex gap-x-1 my-2 mx-2'>
                <label htmlFor="demo" className="block text-md font-medium text-gray-700 mb-1">
                  Demo
                </label>
                <input
                  type="checkbox"
                  id="demo"
                  autoComplete="off"
                  className="w-6 h-6 rounded-md border-gray-500 shadow-sm sm:text-sm"
                  onChange={() => setReasonValue("Demo")}
                />
              </div>
            </div>

            {/* Notes */}
            <div className=' gap-x-2 my-3 mx-2 pt-2'>
              <div className="block text-lg font-medium text-gray-700 mb-1 underline">
                Notes
              </div>
            </div>
            <div className=' gap-x-2 my-2 mx-2'>
              <textarea
                id="note"
                value={note}
                autoComplete="off"
                className="w-full rounded-md border-gray-500 shadow-sm sm:text-sm"
                rows={4}
                placeholder="Enter any additional notes..."
                onChange={(event) => setNote(event.target.value)}
              ></textarea>
            </div>

            <div className='gap-x-2 my-4 mx-2 flex justify-center'>
              <button
                type='submit'
                className="inline-block shrink-0 rounded-md border border-[#5eb65a] bg-[#5eb65a] px-12 py-3 text-sm font-medium text-white transition"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        </>
  );
}

export default App;
